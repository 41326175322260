/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
	Typography
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";

/**
 * Form specific: Build input with type and options given
 */
function FormChangeStatusReturn({ action, actionObject, handleClose, handleSubmit }) {
	const { selectedConversation } = useSelector(state => state.cockpit);

	const [status, setStatus] = useState("");
	const [checkReturn, setCheckReturn] = useState(true);
	const [listOfStatus, setListOfStatus] = useState(actionObject?.config.reasons ?? []);

	useEffect(() => {
		if (
			!lod_.isNil(actionObject?.config?.status) &&
			!lod_.isEmpty(actionObject?.config?.status) &&
			!lod_.isNil(selectedConversation?.meta?.return)
		) {
			setListOfStatus(actionObject?.config?.status);
			setCheckReturn(true);
		} else {
			setCheckReturn(false);
			setListOfStatus([]);
		}

		if (
			!lod_.isEmpty(selectedConversation?.meta?.return?.status) &&
			!lod_.isNil(selectedConversation?.meta?.return?.status)
		) {
			setStatus(selectedConversation?.meta?.return?.status);
		} else {
			setStatus("");
		}
	}, []);

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{`Changer le statut du retour de la commande`}</DialogTitle>
				<DialogContent dividers>
					{checkReturn === false ? (
						<Box style={{ display: "flex", justifyContent: "center" }}>
							<Typography>{`Aucun retour pour cette commande`}</Typography>
						</Box>
					) : (
						<Box>
							<Box paddingBottom={2}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="select-label">Statut</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										label="Statut"
										value={status}
										onChange={e => setStatus(e.target.value)}
									>
										{listOfStatus
											.sort((a, b) => a?.label.localeCompare(b?.label))
											.map((code, index) => (
												<MenuItem key={index} value={code.code}>
													{code.label}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Box>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
						{i18n.t("CD.cancel")}
					</Button>
					<Button
						onClick={() =>
							handleSubmit(
								{
									newStatus: status,
									queueAction: actionObject?.config?.queueAction,
									returnID: selectedConversation?.header?.extra?.externalInfos?.fID
								},
								action
							)
						}
						style={{ margin: "2%" }}
						color="primary"
						disabled={lod_.isEmpty(status)}
					>
						{i18n.t("CD.validate")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default FormChangeStatusReturn;
