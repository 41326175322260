/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import GetFlag from "../../helpers/GetFlag";
import {
	Badge,
	Box,
	CircularProgress,
	IconButton,
	Popover,
	Tooltip,
	Chip,
	Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import NotificationImportantOutlinedIcon from "@material-ui/icons/NotificationImportantOutlined";
import { getUploadS3SignedUrlImage, uploadS3FromSignedUrl } from "helpers/s3.js";
import i18n from "i18next";
import lod_ from "lodash";
import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchUploadImageToS3 } from "redux/reducers/s3Reducers.js";
import { getEditMesssageItemSelector } from "redux/selectors/cockpitSelectors.js";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { fr } from "suneditor/src/lang";
import plugins from "suneditor/src/plugins";
import C from "../../constants/cockpit.js";
import EditorAttachments from "../../helpers/EditorAttachments";
import { cleanHTMLSpecificPatterns } from "../../helpers/html";
import cockpitActions from "../../redux/actions/cockpitActions";
import Autosave from "./Autosave";
import "./CockpitStyleJs/EditMessageItem.css";
import DragAndDrop from "./DragAndDrop.js";
import { SubjectInputComponent } from "./EditMessageComponents/SubjectInputComponent.js";
import { SendMessageComponent } from "./EditMessageComponents/SendMessageComponent";
import EmailCCList from "./EmailCcList.js";
import addAttachment from "./plugins/AddAttachment";
import { setSendSucceed } from "redux/reducers/conversationReducers.js";
import { EmojiObjects, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@material-ui/icons";
import { Remove, HighlightOff } from "@material-ui/icons";
import { traceError } from "../../helpers/utilities";
import TranslateIcon from "@material-ui/icons/Translate";
import BackspaceIcon from "@material-ui/icons/Backspace";
import TextSelectActionPopover from "./Message/TextSelectActionPopover";
import { tr } from "date-fns/locale";
import DropMenu from "components/Menu/DropMenu";
import CopyToClipboardDialog from "./CopyToClipboardDialog";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import ReplyAssistantModal from "./ConvActions/ReplyAssistant";
import GenerateAnswerReply from "./ConvActions/GenerateAnswerReply";

const ALLOWED_DRAFT_CONVERSATION_TYPE = ["ticket", "review"];

export function* generateRandomId() {
	while (true) {
		yield Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	}
}

const EditMessageItem = forwardRef(() => {
	// constants
	const MAX_EDITOR_SIZE = 400;
	const MIN_EDITOR_SIZE = 60;

	const dispatch = useDispatch();
	const suneditor = useRef();

	var fileInputRef = useRef();
	const attachmentsRef = useRef();
	const containerRef = useRef();
	let {
		selectedConversation,
		postDraftLoading,
		addedContent,
		convSending,
		currentMessage,
		attachments,
		isUploading,
		urgent,
		htmlToInsert,
		compositeUploadUrl
	} = useSelector(getEditMesssageItemSelector, shallowEqual);
	// user cockpit settings

	/**
	 * ---------------------------------------------------------------------------------
	 */
	/**
	 * STOP : ARRETEZ CE GENRE DE DEV QUI EST UNE STUPIDITE
	 */
	// let { displayEditor } = useSelector(state => state?.userStatus?.auth?.user?.cockpitSettings);

	let displayEditor = useSelector(
		state => state?.userStatus?.auth?.user?.cockpitSettings
	)?.displayEditor;
	/**
	 * ---------------------------------------------------------------------------------
	 */

	const { sendSucceed } = useSelector(state => state.conversation);
	const { cockpit, assistantconfig } = useSelector(state => state);
	//const { languages } = useSelector(state => state.assistantconfig);
	const { languages } = useSelector(state => {
		let data = [];
		const langueParams = state?.assistantconfig.actions.filter(
			word => word.action === "translateMessage"
		);
		if (langueParams?.length > 0 && langueParams[0]?.queryParams?.languages?.length > 0) {
			data = langueParams[0].queryParams.languages;
			return {
				languages: data
			};
		}
		return { languages: [] };
	});
	const [answer, setAnswer] = useState("");
	const [answerNew, setAnswerNew] = useState("");
	const [answerNewReview, setAnswerNewReview] = useState("");

	const [loadingAnswer, setLoadingAnswer] = useState(true);
	const [draftSignature, setDraftSignature] = useState("");
	const [replyOrForw, setReplyOrForw] = useState("");

	const [containerHeight, setContainerHeight] = useState(MAX_EDITOR_SIZE);
	const [attachmentsHeight, setAttachmentsHeight] = useState(0);
	const [isFocus, setIsFocused] = useState(false);
	const versionCockpit = true;
	const typeConv = selectedConversation?.header.type;
	const [anchorCCPopover, setAnchorCCPopover] = useState(null);
	const [anchorTranslate, setAnchorTranslate] = useState(null);
	const [anchorCopy, setAnchorCopy] = useState(null);

	const [openTranslate, setOpenTranslate] = useState(false);
	const [openCopy, setOpenCopy] = useState(false);

	const [anchorExtraActions, setAnchorExtraActions] = useState(null);
	const [emailCCListGlobal, setEmailCCListGlobal] = useState([]);
	const [emailBCCListGlobal, setEmailBCCListGlobal] = useState([]);
	const [hasTradAnswer, setHasTradAnswer] = useState(false);
	const [hasTradTopic, setHasTradTopic] = useState(false);
	const [signature, setSignature] = useState(false);
	const [replyForward, setReplyForward] = useState(false);
	const [focusSunEditor, setFocusSunEditor] = useState("answer");
	const [lengthCC, setLengthCC] = useState(0);
	const [lengthBCC, setLengthBCC] = useState(0);
	const [textSelectForceClose, setTextSelectForceClose] = useState(false);
	const [openCopyToClipboardDialog, setOpenCopyToClipboardDialog] = useState(false);

	const textareaRef = useRef(null);

	const [replyAssistant, setReplyAssistant] = useState({ open: false });
	const [replyGenerate, setReplyGenerate] = useState({ open: false });

	const [recoputeClear, setRecoputeClear] = useState(false);

	const componentRef = useRef();

	const { topicDraft } = useSelector(state => {
		return {
			topicDraft: state?.cockpit?.topicDraft
		};
	});

	const user = useSelector(state => state.userStatus.auth.user);
	const assignedConv = selectedConversation.agent?.uid === user.email;
	const convSelected = useSelector(state => state.cockpit.selectedConversation);

	let testAnwser = document.createElement("div");
	testAnwser.innerHTML = selectedConversation?.drafts?.version === "2.0" ? answerNewReview : answer;

	// Extraire la réponse pour tester si elle est vide
	let text = testAnwser.textContent || testAnwser.innerText;
	let anwserNotEmpty = /\S/.test(text);

	let testAnwserNew = document.createElement("div");
	testAnwserNew.innerHTML = answerNew;

	// Extraire la réponse pour tester si elle est vide
	let textNew = testAnwserNew.textContent || testAnwserNew.innerText;
	let anwserNewNotEmpty = /\S/.test(textNew);

	const emptyMessageTicket = !anwserNewNotEmpty && attachments.length === 0;

	const emptyMessageConversational =
		text === "undefined" || (!anwserNotEmpty && attachments.length === 0);

	let disableActions = emptyMessageTicket || !assignedConv;

	const currentChannel = selectedConversation?.header.channel;
	const assistantID = selectedConversation?.header.assistantID;

	let disableClearTicket = false;
	let disableClearConversational =
		answer === "" && !currentMessage?.message?.payload?.subject && !attachments?.length;

	const checkActionReply = actions => {
		if (lod_.isNil(actions) || lod_.isEmpty(actions)) {
			return [];
		}
		const filteredActions = actions.filter((action, key) => {
			let conditions = action?.conditions ?? {};
			for (let key of Object.keys(conditions)) {
				let value = conditions[key];
				if (lod_.get(selectedConversation, key) === value) {
					return true;
				}
			}
		});
		return filteredActions ?? [];
	};

	let editorActions = checkActionReply(
		assistantconfig?.actions?.filter(action => action.type === "editor")
	);

	/**
	 * Watch suneditor.current, wait it is up for fillAnswer.
	 * It fixes the follwing bug : after cockpit load, the first conv don't have any content.
	 * It is  because at this moment, suneditor is not up yet.
	 */
	useEffect(() => {
		if (suneditor?.current?.getContents && mustUseDraft(selectedConversation)) {
			let contents = suneditor.current.getContents();
			if (contents === "<p><br></p>") {
				if (selectedConversation?.drafts?.version === "2.0") {
					const draftAnswer = selectedConversation?.drafts?.draftAnswerParts?.answer || "";
					fillAnswerNew(draftAnswer);
				} else {
					const draftAnswer = selectedConversation?.drafts?.draftAnswer || "";
					fillAnswerNew(draftAnswer);
				}
			}
		}
	}, [suneditor.current]);

	/**
	 * When the attachments changes we want to save them in draft
	 */
	useEffect(() => {
		setTimeout(() => {
			if (attachmentsRef && attachmentsRef.current) {
				setAttachmentsHeight(attachmentsRef.current.getBoundingClientRect().height);
			}
		}, 100);

		const fID = selectedConversation?.header?.fID;
		// If substate is "draft", there are specific things in postDraft
		const substate = selectedConversation?.header?.substate;

		let attachementsCopy = lod_.cloneDeep(attachments);
		attachementsCopy = attachementsCopy.map(att => {
			if (att?.signedUrl) {
				delete att.signedUrl;
			}
			if (att?.file?.url) {
				att.url = att.file.url;
				delete att.file;
			}
			if (att?.progress) {
				delete att.progress;
			}
			return att;
		});

		let newDraftValues = {
			attachments: attachementsCopy
		};

		dispatch(cockpitActions.postDraft(fID, assistantID, substate, newDraftValues));
	}, [attachments]);

	/**
	 * initialize answer
	 */
	useEffect(() => {
		if (
			selectedConversation?.drafts?.version === "2.0" &&
			selectedConversation.header.type === "ticket"
		) {
			setAnswerNew("");
		} else if (selectedConversation?.drafts?.version === "2.0") {
			setAnswerNewReview("");
		} else {
			setAnswer("");
		}
		dispatch({ type: "CLEAR_ATTACHMENTS" });

		// Message array can be empty and body can be empty too!
		if (
			selectedConversation.messages?.length > 0 &&
			selectedConversation.messages[selectedConversation.messages.length - 1].body
		) {
			let isUrgent = selectedConversation.messages[selectedConversation.messages?.length - 1].body
				.urgent
				? true
				: false;
			dispatch({ type: C.SET_URGENT, payload: isUrgent });
		}

		dispatch(cockpitActions.getMID({ assistantID }));

		// Initialize states with draft content

		const draft = selectedConversation?.drafts;

		if (draft && mustUseDraft(selectedConversation)) {
			// Subject
			const draftTopicT = draft.draftTopic;
			if (typeof draftTopicT === "string") {
				dispatch({ type: C.SET_TOPIC_DRAFT, payload: draftTopicT });
			}
			// Attachments
			const draftAttachments = draft.attachments;
			if (Array.isArray(draftAttachments) && draftAttachments.length > 0) {
				dispatch({
					type: C.SET_ATTACHMENT_DRAFT,
					payload: draftAttachments
				});
			}
			// CC
			let emailCC = [];
			if (Array.isArray(draft.emailCC)) {
				emailCC = draft.emailCC;
			}
			setEmailCCListGlobal(emailCC);
			setLengthCC(emailCC.length);

			// BCC
			let emailBCC = [];
			if (Array.isArray(draft.emailBCC)) {
				emailBCC = draft.emailBCC;
			}
			setEmailBCCListGlobal(emailBCC);
			setLengthBCC(emailBCC.length);

			if (draft.version === "2.0" && selectedConversation.header.type === "ticket") {
				fillAnswerNew(draft.draftAnswerParts?.answer || "");

				setAnswerNew(draft.draftAnswerParts?.answer || "");
				setDraftSignature(draft.draftAnswerParts?.signature || null);
				setReplyOrForw(draft.draftAnswerParts?.replyTo || null);
			} else if (draft.version === "2.0") {
				setAnswerNewReview(draft.draftAnswerParts?.answer || "");
				setDraftSignature(draft.draftAnswerParts?.signature || null);
				setReplyOrForw(draft.draftAnswerParts?.replyTo || null);
			} else {
				fillAnswerNew(draft.draftAnswer || "");
				setAnswer(draft.draftAnswer || "");

				setDraftSignature(null);
				setReplyOrForw(null);
			}
			setReplyForward(false);
			setSignature(false);
		}

		setIsFocused(false);

		setContainerHeight(displayEditor ? MAX_EDITOR_SIZE : MIN_EDITOR_SIZE);
	}, [selectedConversation?.header?.fID]);

	useEffect(() => {
		if (recoputeClear) {
			setRecoputeClear(false);
			if (
				selectedConversation.drafts?.version == "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew(selectedConversation.drafts?.draftAnswerParts?.answer);
				setDraftSignature(selectedConversation.drafts?.draftAnswerParts?.signature);
				setReplyOrForw(selectedConversation.drafts?.draftAnswerParts?.replyTo);
			} else if (selectedConversation.drafts?.version == "2.0") {
				setAnswerNewReview(selectedConversation.drafts?.draftAnswerParts?.answer);
				setDraftSignature(selectedConversation.drafts?.draftAnswerParts?.signature);
				setReplyOrForw(selectedConversation.drafts?.draftAnswerParts?.replyTo);
			} else {
				setAnswer(selectedConversation.drafts?.draftAnswer);
			}
		} else {
			// Draft can be updated by other components (FAIQ for exemple)
			if (
				selectedConversation.drafts?.version == "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew(selectedConversation.drafts?.draftAnswerParts?.answer);
			} else if (selectedConversation.drafts?.version == "2.0") {
				setAnswerNewReview(selectedConversation.drafts?.draftAnswerParts?.answer);
			} else {
				setAnswer(selectedConversation.drafts?.draftAnswer);
			}
		}
	}, [selectedConversation?.drafts]);
	/**
	 * Add a content in the answer
	 * Used by MessageItem to add the received content in the answer
	 */
	useEffect(() => {
		if (addedContent) {
			enhanceAnswer();
			setIsFocused(false);
		}
	}, [addedContent]);

	/**
	 * ?
	 */
	useEffect(() => {
		if (currentMessage?.message?.payload?.text?.plain_text) {
			if (!convSending) {
				/* Only update the message content if not sending! Used for Answer Items for example
					The problem is that when we send a message, we modify currentMessage, 
					but we don't want to update the editor with its content */
				if (
					selectedConversation?.drafts?.version === "2.0" &&
					selectedConversation.header.type === "ticket"
				) {
					setAnswerNew(currentMessage.message.payload.text.plain_text);
				} else if (selectedConversation?.drafts?.version == "2.0") {
					setAnswerNewReview(currentMessage.message.payload.text.plain_text);
				} else {
					setAnswer(currentMessage.message.payload.text.plain_text);
				}
			}
		} else if (currentMessage?.message?.payload?.text?.html_text) {
			if (
				selectedConversation?.drafts?.version === "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew(cleanHTMLSpecificPatterns(currentMessage.message.payload.text.html_text));
			} else if (selectedConversation?.drafts?.version == "2.0") {
				setAnswerNewReview(
					cleanHTMLSpecificPatterns(currentMessage.message.payload.text.html_text)
				);
			} else {
				setAnswer(cleanHTMLSpecificPatterns(currentMessage.message.payload.text.html_text));
			}
		} else if (currentMessage?.message?.header?.type === "menu") {
			if (
				selectedConversation?.drafts?.version === "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew("");
			} else if (selectedConversation?.drafts?.version == "2.0") {
				setAnswerNewReview("");
			} else {
				setAnswer("");
			}
		} else if (currentMessage?.message?.payload === {}) {
			// Always false
			handleClearAnswer();
		} else if (!currentMessage) {
			dispatch(cockpitActions.getMID({ assistantID }));
		}
	}, [currentMessage]);

	/**
	 * When we want to insert an answer_type in the answer (for type ticket)
	 */
	useEffect(() => {
		if (htmlToInsert) {
			if (
				selectedConversation.header.type === "conversational" ||
				selectedConversation.header.type === "review"
			) {
				const textarea = textareaRef.current;
				const { selectionStart, selectionEnd } = textarea;

				switch (selectedConversation.header.type) {
					case "conversational": {
						let cloneAnswer = lod_.cloneDeep(answer);

						const updatedText =
							cloneAnswer.substring(0, selectionStart) +
							" " +
							htmlToInsert +
							" " +
							cloneAnswer.substring(selectionEnd);

						setAnswer(updatedText);
						break;
					}
					case "review": {
						let cloneAnswer = lod_.cloneDeep(answerNewReview);

						const updatedText =
							cloneAnswer.substring(0, selectionStart) +
							" " +
							htmlToInsert +
							" " +
							cloneAnswer.substring(selectionEnd);

						setAnswerNewReview(updatedText);
						break;
					}
				}
			} else {
				if (suneditor.current && suneditor.current?.setContents && focusSunEditor === "answer") {
					suneditor.current.core.focus();
					suneditor.current.insertHTML(cleanHTMLSpecificPatterns(htmlToInsert), true, true);
					handleSaveDraft(suneditor.current.getContents(), emailCCListGlobal, emailBCCListGlobal);
				}
			}
			dispatch({ type: C.CLEAR_INSERT_HTML });
		}
	}, [htmlToInsert]);

	/**
	 * clear the text after we send a message
	 */
	useEffect(() => {
		if (sendSucceed === selectedConversation?.header?.fID) {
			if (!ALLOWED_DRAFT_CONVERSATION_TYPE.includes(typeConv)) {
				if (
					selectedConversation?.drafts?.version === "2.0" &&
					selectedConversation.header.type === "ticket"
				) {
					setAnswerNew("");
				} else if (selectedConversation?.drafts?.version === "2.0") {
					setAnswerNewReview("");
				} else {
					setAnswer("");
				}
			}
			dispatch(setSendSucceed(false));
		}
	}, [sendSucceed]);

	/**
	 * Click to upload managment
	 * --> Auth-dev send wsEvent to update compositeUploadUrl in ws of store
	 * --> Store update is listen here
	 */
	useEffect(() => {
		if (!compositeUploadUrl) return;

		/**
		 * If auth-dev send error, add url to editor
		 */
		if (compositeUploadUrl.error) {
			if (compositeUploadUrl.simpleURL) {
				suneditor.current.insertHTML(
					`<a href="${compositeUploadUrl.simpleURL}">${compositeUploadUrl.simpleURL}</a>`
				);
			}
		} else {
			/**
			 * Add file to attachments
			 */
			let file = compositeUploadUrl.fileObject;
			const randomId = generateRandomId().next().value;

			dispatch({
				type: C.ADD_ATTACHMENT,
				payload: {
					id: randomId,
					name: file.name,
					mimeType: file.contentType,
					size: parseInt(file.size),
					file,
					signedUrl: compositeUploadUrl.signedUrl
				}
			});

			/**
			 * Clear compositeUploadUrl in store
			 */
			dispatch({
				type: "ws/delete_attachments",
				payload: null
			});
		}
	}, [compositeUploadUrl]);

	// Defines if the conversation is using the draft functionnality.
	const mustUseDraft = selectedConv => {
		return ALLOWED_DRAFT_CONVERSATION_TYPE.includes(selectedConv?.header?.type);
	};

	/**
	 * Upload and links the file to the message
	 * @param {Object} files Files object from input html
	 */
	const addAttachmentsToMessage = files => {
		Array.from(files).forEach(file => {
			let fileSize = -1;
			if (file) {
				fileSize = file.size;
			}
			if (fileSize > 0) {
				dispatch(
					fetchUploadImageToS3(assistantID, file, "messageItem", {
						mID: currentMessage.mID
					})
				);
			}
		});
	};

	/**
	 * Add an attachment with button attachments
	 * @param {Object} e event with files info
	 */
	const readFileHandler = e => {
		const files = e.target?.files || e;
		addAttachmentsToMessage(files);
		fileInputRef.current.value = "";
	};

	/**
	 * Add an attachment with drag and drop
	 * @param {Objects} files files info to send
	 */
	const readFileHandlerDragAndDrop = files => {
		addAttachmentsToMessage(files);
	};

	/**
	 *
	 * @param {*} str
	 */
	const AutoSaveDraft = str => {
		if (isFocus) {
			handleSaveDraft(str, emailCCListGlobal, emailBCCListGlobal);
		}
	};

	/**
	 *
	 * @param {*} text
	 * @param {*} emailCC
	 * @param {*} emailBCC
	 * @param {*} topic
	 */
	const handleSaveDraft = (
		text,
		emailCC,
		emailBCC,
		topic,
		replyText = "",
		isReplyEdit = false,
		signature = "",
		isSignature = false
	) => {
		const fID = selectedConversation?.header?.fID;
		const substate = selectedConversation?.header?.substate;

		let newDraftValues = {};

		if (selectedConversation?.drafts?.version === "2.0") {
			if (isReplyEdit) {
				newDraftValues = {
					draftAnswerParts: { answer: text, replyTo: replyText, signature: draftSignature },
					emailCC,
					emailBCC
				};
			} else if (isSignature) {
				newDraftValues = {
					draftAnswerParts: {
						answer: text,
						replyTo: replyText === null ? "" : replyText,
						signature: signature
					},
					emailCC,
					emailBCC
				};
			} else {
				newDraftValues = {
					draftAnswerParts: {
						answer: text,
						replyTo: replyOrForw === null ? "" : replyOrForw,
						signature: draftSignature
					},
					emailCC,
					emailBCC
				};
			}
		} else {
			newDraftValues = {
				draftAnswer: text,
				emailCC,
				emailBCC
			};
		}

		if (typeof topic === "string") {
			newDraftValues.draftTopic = topic;
		}

		dispatch(cockpitActions.postDraft(fID, assistantID, substate, newDraftValues));
		setLoadingAnswer(true);
	};

	const handleOpenReplyG = actions => {
		if (selectedConversation?.header?.type === "ticket") {
			setReplyGenerate({
				open: true,
				actions
			});
		} else {
			setReplyAssistant({
				open: true,
				actions
			});
		}
	};

	/**
	 * To Edit Text Html
	 */
	const handleRichAnswerChange = content => {
		setAnswerNew(content);
		setAnswer(content);

		let testAnwser = document.createElement("div");
		testAnwser.innerHTML = content;

		let text = testAnwser.textContent || testAnwser.innerText;
		let anwserNotEmpty = /\S/.test(text);

		if (convSelected?.drafts?.draftAnswerParts?.answer === content) {
			setLoadingAnswer(true);
		} else {
			if (anwserNotEmpty) {
				setLoadingAnswer(false);
			} else {
				setLoadingAnswer(true);
			}
		}

		// When user types in, we want to force the close of the <TextSelectActionPopover> then restore it
		setTextSelectForceClose(true);

		setTimeout(() => {
			setTextSelectForceClose(false);
		}, 100);

		setTimeout(() => {
			setLoadingAnswer(true);
		}, 2000);
	};

	const handleDeleteSignature = () => {
		handleSaveDraft(
			answerNew,
			emailCCListGlobal,
			emailBCCListGlobal,
			topicDraft,
			replyOrForw,
			false,
			"",
			true
		);
	};

	const handleDeleteReplyTo = () => {
		handleSaveDraft(answerNew, emailCCListGlobal, emailBCCListGlobal, topicDraft, "", true);
	};

	const deleteAttachment = (id, fileName) => {
		dispatch(cockpitActions.deleteFile(id, fileName, selectedConversation?._id, assistantID));
	};

	/**
	 * Toggle action between
	 * - Clean the editor
	 * - Put the draft back
	 */
	const handleClearAnswer = () => {
		const suneditorReady = suneditor?.current?.getContents;

		if (typeConv === "ticket") {
			if (suneditorReady) {
				const isEditorEmpty = suneditor.current.getContents() === "<p><br></p>";

				if (isEditorEmpty && mustUseDraft(selectedConversation)) {
					// Set draft
					setRecoputeClear(true);
					dispatch(
						cockpitActions.computeDraft(
							selectedConversation?.header?.assistantID,
							selectedConversation?.header?.fID
						)
					);
				} else {
					// Clear editor
					dispatch({ type: C.SET_TOPIC_DRAFT, payload: "" });
					setEmailCCListGlobal([]);
					setEmailBCCListGlobal([]);
					suneditor.current.setContents("");
					attachments.forEach(attachement => {
						deleteAttachment(attachement.id, attachement.name);
					});
					dispatch({
						type: C.SET_ATTACHMENT_DRAFT,
						payload: []
					});
					handleSaveDraft("", [], [], "");
				}
			}
		} else {
			handleSaveDraft("", [], [], "");
			dispatch({
				type: C.CLEAR_CONTENT_MESSAGE
			});
		}
	};

	/**
	 * Get the html of one of the previous message
	 * @param {string} subject the subject of the previous message
	 * @param {string} date the date of the previous message
	 * @param {string} content the content of the previous message
	 * @returns the html
	 */
	const getPreviousMessageHTML = (subject, date, content, cleanAnswer) => {
		const limiter = i18n.t("COC.quickForward_line");
		const contactEmail = selectedConversation.contact.email;

		return `${!cleanAnswer ? answer : ""}<br> ${limiter}
			<br>${i18n.t("EDIT_FORM.metaTopic")}: ${subject}
			<br>${i18n.t("COC.From")} ${date}, ${contactEmail} ${i18n.t("COC.Wrote")} 
			<br>     <br>  
			<p>${content}</p><br>`;
	};

	/* 
	Allow user to add the selected message under is signing
	*/
	const enhanceAnswer = () => {
		const content = addedContent.content;
		const date = addedContent.date;
		const subject = addedContent.subject;

		const editor = suneditor.current;

		let previousHTMLMessage = getPreviousMessageHTML(subject, date, content);
		let newContent = `${previousHTMLMessage}`;

		// editor.setSelection(range.index);
		if (selectedConversation?.drafts?.version === "2.0") {
			const presetReply = (replyOrForw === null ? "" : replyOrForw) + newContent;
			handleSaveDraft(
				answerNew,
				emailCCListGlobal,
				emailBCCListGlobal,
				topicDraft,
				presetReply,
				true
			);
			handleClickrReplyForward();
			setReplyOrForw(presetReply);
		} else {
			editor?.insertHTML(cleanHTMLSpecificPatterns(newContent)); // This one
			handleSaveDraft(newContent, emailCCListGlobal, emailBCCListGlobal, topicDraft);
		}

		dispatch({ type: C.CLEAR_CONTENT });
	};

	const fillAnswerNew = content => {
		if (!suneditor?.current || !suneditor?.current?.setContents) {
			// Don't do anything with rich editor if editor is not up
			return;
		}
		const editor = suneditor.current;
		const newContent = cleanHTMLSpecificPatterns(content);
		editor.setContents(newContent);
	};

	const StyledBadge = withStyles(theme => ({
		badge: {
			right: 0,
			top: 0,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: "0 4px",
			backgroundColor: "#545454"
		}
	}))(Badge);

	const getSunEditorInstance = sunEditor => {
		suneditor.current = sunEditor;
		suneditor.current.onPaste = function (e, cleanData, maxCharCount, core) {
			handlePaste(e, cleanData, maxCharCount, core);
		};
	};

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} imageFile the file to upload with html file-input format
	 */
	const uploadImageToS3 = async imageFile => {
		let fileName = imageFile.name;
		let contentType = imageFile.type;
		let size = imageFile.size;

		try {
			const compositeData = await getUploadS3SignedUrlImage(
				fileName,
				contentType,
				size,
				"messageItem"
			);

			await uploadS3FromSignedUrl(compositeData.signedUrl, imageFile);
			return compositeData;
		} catch (error) {
			traceError(
				`uploadImageToS3-fileName=${fileName}, contentType=${contentType} failed: ${error.message}`
			);
		}
	};

	function removeDragImage(e) {
		if (currentChannel === "MK" || currentChannel === "PK") {
			e.dataTransfer.dropEffect = "none";
			e.stopPropagation();
			e.preventDefault();
		} //drag on drop image is available for other channels
	}
	// Button states

	const handleAddAttachment = () => {
		fileInputRef.current.click();
	};

	/**
	 * On paste we want to replace url with a anchor to create a clickable link
	 * @param {object} event javascript ClipboardEvent
	 */
	const handlePaste = (event, cleanData, maxCharCount, core) => {
		// const clipboardData = event.clipboardData || window.clipboardData;
		const pastedData = cleanData; //clipboardData.getData("Text");
		const linkExpression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
		const emailExpression =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (pastedData.match(linkExpression)) {
			suneditor.current.insertHTML(`<a href="${pastedData}"></a>`);
			return false;
		} else if (pastedData.match(emailExpression)) {
			suneditor.current.insertHTML(`<a href="mailto:${pastedData}"></a>`);
			return false;
		}

		return true;
	};

	function reduceEditor() {
		setContainerHeight(MIN_EDITOR_SIZE);
		setAttachmentsHeight(0);
	}

	function expandEditor() {
		setContainerHeight(MAX_EDITOR_SIZE);
		setAttachmentsHeight(attachmentsRef.current.getBoundingClientRect().height);
	}

	function reduceEditorConversationnel() {
		setContainerHeight(MIN_EDITOR_SIZE);
	}

	function expandEditorConversationnel() {
		setContainerHeight(MAX_EDITOR_SIZE);
	}

	function handleResize(e) {
		if (containerHeight === MAX_EDITOR_SIZE) {
			reduceEditor();
		} else {
			expandEditor();
		}
	}

	function handleResizeConversationnel(e) {
		if (containerHeight === MAX_EDITOR_SIZE) {
			reduceEditorConversationnel();
		} else {
			expandEditorConversationnel();
		}
	}

	const translateDraft = event => {
		setAnchorTranslate(event.currentTarget);
		setOpenTranslate(true);
	};

	const showCopyAction = event => {
		setAnchorCopy(event.currentTarget);
		setOpenCopy(true);
	};

	const handleCloseCopy = () => {
		setOpenCopy(false);
		setAnchorCopy(null);
	};

	const handleClose = () => {
		setOpenTranslate(false);
		setAnchorTranslate(null);
	};

	/**
	 * When the attachments changes we want to save them in draft
	 */
	useEffect(() => {
		if (hasTradAnswer) {
			setAnswer(selectedConversation.drafts?.draftAnswer);

			setHasTradAnswer(false);
		}
	}, [selectedConversation?.drafts?.draftAnswer]);

	useEffect(() => {
		if (hasTradAnswer) {
			if (
				selectedConversation.drafts?.version === "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew(selectedConversation.drafts?.draftAnswerParts?.answer);
			} else if (selectedConversation.drafts?.version === "2.0") {
				setAnswerNewReview(selectedConversation.drafts?.draftAnswerParts?.answer);
			}

			setHasTradAnswer(false);
		}
	}, [selectedConversation?.drafts?.draftAnswerParts?.answer]);

	useEffect(() => {
		if (hasTradTopic) {
			const draftTopicT = selectedConversation.drafts?.draftTopic;
			dispatch({ type: C.SET_TOPIC_DRAFT, payload: draftTopicT });
			setHasTradTopic(false);
		}
	}, [selectedConversation?.drafts?.draftTopic]);

	const handleTranslate = (langage, topicContent, contentDraft) => {
		setOpenTranslate(false);
		setAnchorTranslate(null);
		let targetLang = langage;
		let fID = selectedConversation?.header?.fID;
		let uid = selectedConversation.agent?.uid;
		const channel = selectedConversation.header?.channel;
		const channelCode = selectedConversation.meta.cockpit?.channelCode;
		let header = { assistantID, fID, uid, channel, channelCode };
		let hasDefault = false;
		if (selectedConversation.drafts?.default) {
			hasDefault = true;
		}
		dispatch(
			cockpitActions.callTranslateDraft(targetLang, topicContent, contentDraft, header, hasDefault)
		);
		setHasTradAnswer(true);
		setHasTradTopic(true);
	};

	const handleSetDefaultDraft = () => {
		if (selectedConversation?.drafts?.default) {
			const draftTopicT = selectedConversation?.drafts?.default?.subject;
			const draftConent = selectedConversation?.drafts?.default?.content;
			if (
				selectedConversation?.drafts?.version === "2.0" &&
				selectedConversation.header.type === "ticket"
			) {
				setAnswerNew(selectedConversation?.drafts?.default?.content);
			} else if (selectedConversation?.drafts?.version === "2.0") {
				setAnswerNewReview(selectedConversation?.drafts?.default?.content);
			} else {
				setAnswer(selectedConversation?.drafts?.default?.content);
			}
			dispatch({ type: C.SET_TOPIC_DRAFT, payload: draftTopicT });
			let fID = selectedConversation?.header?.fID;
			const header = { assistantID, fID };
			dispatch(cockpitActions.callSetDefaultDraft(draftConent, draftTopicT, header));
		}
	};
	const handleImageUpload = (files, info, uploadHandler) => {
		uploadImageToS3(files[0]).then(compositeData => {
			const { name, size } = files[0];
			uploadHandler({
				result: [
					{
						url: compositeData?.fileObject?.url,
						name,
						size
					}
				]
			});
		});
	};
	const handleClickSignature = () => {
		if (draftSignature) setSignature(true);
		if (replyOrForw) setReplyForward(true);
	};

	const handleClickrCloseSignature = () => {
		setSignature(false);
	};

	const handleClickrReplyForward = () => {
		setReplyForward(false);
	};

	const handleClickDeleteForward = () => {
		setReplyOrForw("");
		handleDeleteReplyTo();
		setReplyForward(false);
	};

	const handleClickDeleteSignature = () => {
		handleDeleteSignature();
		setDraftSignature("");
		setSignature(false);
	};

	const onChangeTextAnswer = value => {
		if (selectedConversation?.drafts?.version === "2.0") {
			setAnswerNewReview(value);
		} else {
			setAnswer(value);
		}
	};

	/**
	 * Set BCC and CC for message draft if you have CC or BCC in list of messages of conversation
	 *
	 */
	const replyAll = () => {
		if (selectedConversation?.messages && selectedConversation?.messages.length > 0) {
			let arrayCC = lod_.cloneDeep(emailCCListGlobal);
			let arrayBCC = lod_.cloneDeep(emailBCCListGlobal);

			let ccSet = new Set();
			let bccSet = new Set();
			let toSet = new Set();

			const messages = selectedConversation?.messages;

			// Parcours du tableau de message
			messages.forEach(item => {
				if (item.header.to) {
					if (item.header?.to?.to && lod_.isArray(item.header?.to?.to)) {
						item.header.to.to.forEach(email => toSet.add(email.trim()));
					}
					if (item.header.to?.cc && lod_.isArray(item.header?.to?.cc)) {
						item.header.to.cc.forEach(email => ccSet.add(email.trim()));
					}
					if (item.header.to?.cc && lod_.isArray(item.header?.to?.cc)) {
						item.header.to.bcc.forEach(email => bccSet.add(email.trim()));
					}
				}
			});

			// Conversion des ensembles en tableaux
			let cc_values = Array.from(ccSet);
			let bcc_values = Array.from(bccSet);
			let to_values = Array.from(toSet);

			//Evite les doublons d'ajout dans le tableaux déja existant

			arrayCC = lod_.union(arrayCC, to_values);
			arrayCC = lod_.without(arrayCC, selectedConversation?.header?.specificFromEmailFront);

			arrayCC = lod_.union(arrayCC, cc_values);
			arrayCC = lod_.without(arrayCC, selectedConversation?.header?.specificFromEmailFront);

			arrayBCC = lod_.union(arrayBCC, bcc_values);
			arrayBCC = lod_.without(arrayBCC, selectedConversation?.header?.specificFromEmailFront);

			//Modification du nombre pour le badge
			setLengthCC(arrayCC.length);
			setLengthBCC(arrayBCC.length);

			//Modification du tableau pour ajouter dans la liste
			setEmailCCListGlobal(arrayCC);
			setEmailBCCListGlobal(arrayBCC);

			let answerDraft = selectedConversation?.drafts?.version === "2.0" ? answerNew : answer;

			handleSaveDraft(answerDraft, arrayCC, arrayBCC);
		}
	};

	/**
	 * Set BCC and CC for message draft if you have CC or BCC in list of messages of conversation
	 ** @param {Object} selectedConversation Current conversation
	 * @return true if conv is channel type ML
	 */
	const isChannelMail = selectedConversation => {
		return selectedConversation.header.channel === "ML" ? true : false;
	};

	/**
	 * Set all BCC or CC of conversation for message draft
	 ** @param {Object} selectedConversation Current conversation
	 * @return true if conv is channel type ML && Current conv have one message min && Have one cc or one bcc
	 */
	const shouldShowReplyAllButton = selectedConversation => {
		if (
			isChannelMail(selectedConversation) &&
			selectedConversation.messages.length !== 0 &&
			selectedConversation.messages.some(message => {
				const bccArray = message.header?.to?.bcc;
				const ccArray = message.header?.to?.cc;
				const toArray = message.header?.to?.to;

				return (
					(bccArray && bccArray.length !== 0) ||
					(ccArray && ccArray.length !== 0) ||
					(toArray && lod_.isArray(toArray) && toArray.length >= 2)
				);
			})
		) {
			return true;
		}
		return false;
	};

	const shouldShowTranslateButton = () => {
		if (typeConv === "ticket" && !disableActions) {
			return true;
		}
		if ((typeConv === "conversational" || typeConv === "review") && !emptyMessageConversational) {
			return true;
		}
		return false;
	};

	const handleOpenCopyToClipboardDialog = () => {
		setOpenCopyToClipboardDialog(true);
	};

	const handleCloseCopyToClipboardDialog = () => {
		setOpenCopyToClipboardDialog(false);
		setOpenCopy(false);
	};

	const handleOnKeyUp = (event, current) => {
		const key = event.key;
		const isAllowedCharacter =
			key.length === 1 && key.match(/^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]$/);

		if ((isAllowedCharacter && !event.ctrlKey) || (key === "Enter" && !event.ctrlKey)) {
			setLoadingAnswer(false);
		}

		setIsFocused(true);
	};

	const handleAddCopyToClipboardDialog = () => {
		dispatch(infoMsg("Ajout en cours"));

		let storage = JSON.parse(localStorage.getItem("presse-papier"));
		storage
			?.filter(filter => filter.assistantID === assistantID)
			.forEach(item => {
				if (item.type === "image") {
					dispatch(
						cockpitActions.clickToUpload(
							item.url,
							user.email,
							assistantconfig.assistantID,
							currentMessage.mID || "",
							item.name
						)
					);
				} else if (item.type === "conversation") {
					convToPDF(item);
				}
			});
	};

	function convToPDF(item) {
		let successCB = () => {};
		let failCB = () => {};

		let action = "downloadConversationPDFToClipboard";
		let rcvAtt = [];
		let sntAtt = [];

		dispatch(
			cockpitActions.doAnAction(
				item.fID,
				item.isArchivedConv,
				null,
				action,
				rcvAtt,
				sntAtt,
				null,
				false,
				null,
				false,
				successCB,
				failCB
			)
		);
	}

	const getContentForTextArea = (conversation, message) => {
		// Default case : use answer
		let messageAnswer = answer;

		// Case 1 : We have a draft and conversation is allowed to used draft
		if (selectedConversation?.drafts?.version === "2.0" && mustUseDraft(conversation)) {
			messageAnswer = answerNewReview;
		}

		if (messageAnswer) {
			return messageAnswer;
		}

		// Case 2 : We have a message with a payload menu
		if (message?.message?.payload?.subject) {
			const subject = message.message.payload.subject;
			const choices = message.message.payload.menu?.choices;

			if (choices && choices.length > 0) {
				const labels = choices.map(obj => obj.label);
				return `${subject} [${labels.join(", ")}]`;
			} else {
				return subject;
			}
		}

		// Case 3 : Return empty string
		return "";
	};

	return (
		<div
			ref={containerRef}
			style={{
				height: containerHeight || 0,
				maxHeight: "100%",
				minHeight: containerHeight + attachmentsHeight || 0
			}}
			data-component-name="EditMessageItem"
		>
			<DragAndDrop currentChannel={currentChannel} handleDrop={readFileHandlerDragAndDrop}>
				{selectedConversation.header.type === "ticket" && versionCockpit && (
					<>
						<div
							className="ql-formats"
							style={{
								flex: "1 2 250px",
								display: "flex",
								alignItems: "center",
								margin: "10px 10px 0 10px"
							}}
						>
							<div style={{ lineHeight: "26px", paddingRight: "10px" }}>
								<span
									style={{
										verticalAlign: "center",
										fontFamily: "Roboto",
										fontSize: "16px",
										fontWeight: "normal"
									}}
									className={"ql-subject"}
								>
									{i18n.t("COC.subject")}
								</span>
							</div>
							<SubjectInputComponent setLoading={setLoadingAnswer} />
							<div className="ql-formats" style={{ margin: 0 }}>
								<Tooltip title={i18n.t("COC.urgent")}>
									<IconButton
										onClick={() => dispatch({ type: C.SET_URGENT, payload: !urgent })}
										style={urgent ? { color: "#ff4081" } : { color: "black" }}
										className={"editorUrgent"}
									>
										<NotificationImportantOutlinedIcon style={{ width: "18px" }} />
									</IconButton>
								</Tooltip>
							</div>
							<Tooltip
								title={
									containerHeight === MAX_EDITOR_SIZE
										? i18n.t("COC.minimizeResponseWindow")
										: i18n.t("COC.maximizeResponseWindow")
								}
							>
								<IconButton size="small" onClick={() => handleResize()}>
									{containerHeight === MIN_EDITOR_SIZE && <KeyboardArrowUpRounded />}
									{containerHeight === MAX_EDITOR_SIZE && <KeyboardArrowDownRounded />}
								</IconButton>
							</Tooltip>
						</div>
					</>
				)}
				{((selectedConversation.header.type === "conversational" &&
					currentChannel !== "SMS" &&
					!currentMessage?.message?.payload?.subject) ||
					selectedConversation.header.type === "ticket") && (
					<EditorAttachments
						assistantID={selectedConversation.header.assistantID}
						convID={selectedConversation._id}
						childRef={attachmentsRef}
						showinCockpit={versionCockpit}
					/>
				)}
				{(selectedConversation.header.type === "conversational" ||
					selectedConversation.header.type === "review") && (
					<Box
						style={{
							display: "flex",
							paddingRight: "10px",
							height: "52px",
							justifyContent: "flex-end"
						}}
					>
						<Tooltip
							title={
								containerHeight === MAX_EDITOR_SIZE
									? i18n.t("COC.minimizeResponseWindow")
									: i18n.t("COC.maximizeResponseWindow")
							}
						>
							<IconButton size="small" onClick={() => handleResizeConversationnel()}>
								{containerHeight === MIN_EDITOR_SIZE && <KeyboardArrowUpRounded />}
								{containerHeight === MAX_EDITOR_SIZE && <KeyboardArrowDownRounded />}
							</IconButton>
						</Tooltip>
					</Box>
				)}

				<Box display="flex" p={1} style={{ overflowY: "auto", height: "100%" }}>
					<Box
						display="flex"
						flexDirection="column"
						style={{
							overflowY: "auto",
							width: "100%",
							height: lod_.isEmpty(cockpit?.attachments) ? "90%" : "70%",
							overflowX: "hidden"
						}}
					>
						<input
							type="file"
							multiple
							style={{ display: "none" }}
							onChange={readFileHandler}
							ref={fileInputRef}
						/>
						{selectedConversation.header.type === "ticket" ? (
							<div ref={componentRef}>
								<div
									style={{ minHeight: "130px" }}
									onDragEnter={removeDragImage}
									onDragOver={removeDragImage}
									onDrop={removeDragImage}
								>
									{currentMessage && attachments && (
										<SunEditor
											placeholder={i18n.t("EDIT_FORM.placeholderAnswer")}
											getSunEditorInstance={getSunEditorInstance}
											showToolbar={true}
											onFocus={() => setFocusSunEditor("answer")}
											onChange={handleRichAnswerChange}
											setDefaultStyle="height: 250px; font-size: 16px; font-family: Roboto; font-weight: normal"
											onKeyUp={handleOnKeyUp}
											setContents={
												selectedConversation?.drafts?.version === "2.0" ? answerNew : answer
											}
											onImageUploadBefore={handleImageUpload}
											setOptions={{
												buttonList: [
													[
														"bold",
														"underline",
														"italic",
														"strike",
														"fontSize",
														"fontColor",
														"hiliteColor"
													],
													["list", "align"],
													["image", "add_attachment"],
													["link"],
													["-right", "fullScreen"]
												],
												resizingBar: false,
												plugins: {
													...plugins,
													attachmentPlugin: addAttachment({ handleAddAttachment })
												},
												lang: fr,
												showPathLabel: false
											}}
										/>
									)}
									{isFocus && (
										<Autosave
											AutoSaveDraft={AutoSaveDraft}
											answer={selectedConversation?.drafts?.version === "2.0" ? answerNew : answer}
										/>
									)}
								</div>

								<TextSelectActionPopover target={componentRef} forceClose={textSelectForceClose} />

								<div style={{ display: "flex", flexDirection: "column" }}>
									{signature && (
										<Box>
											<IconButton onClick={handleClickrCloseSignature}>
												<Remove />
											</IconButton>
											<IconButton onClick={handleClickDeleteSignature}>
												<HighlightOff />
											</IconButton>
											<div dangerouslySetInnerHTML={{ __html: draftSignature }} />
										</Box>
									)}

									{replyForward && (
										<Box>
											<IconButton onClick={handleClickrReplyForward}>
												<Remove />
											</IconButton>
											<IconButton onClick={handleClickDeleteForward}>
												<HighlightOff />
											</IconButton>
											<div dangerouslySetInnerHTML={{ __html: replyOrForw }} />
										</Box>
									)}
								</div>
							</div>
						) : (
							<Box style={{ height: "100%", width: "100%" }}>
								<textarea
									ref={textareaRef}
									style={{
										height: (containerHeight || 80) - 80,
										resize: "none",
										width: "100%",
										minHeight: "200px",
										maxHeight: containerHeight || 0,
										fontSize: "16px",
										fontFamily: "Roboto"
									}}
									disabled={currentMessage?.message?.payload?.subject}
									value={getContentForTextArea(selectedConversation, currentMessage)}
									onChange={e => onChangeTextAnswer(e.target.value)}
								/>

								{selectedConversation.header.type === "conversational" &&
									currentChannel !== "SMS" &&
									!currentMessage?.message?.payload?.subject &&
									!attachments?.length &&
									(isUploading ? (
										<Box style={{ height: "20px" }}>
											<CircularProgress size="14px" />
										</Box>
									) : (
										<Box>
											<IconButton
												size="small"
												disabled={false}
												onClick={() => fileInputRef.current?.click()}
											>
												<AttachmentIcon />
											</IconButton>
										</Box>
									))}
							</Box>
						)}
					</Box>
					<Box display="flex" height="80%" flexDirection="column" m={1}>
						{/* Send message */}
						<Box>
							<SendMessageComponent
								answer={
									selectedConversation?.drafts?.version === "2.0"
										? selectedConversation?.header?.type === "ticket"
											? answerNew
											: answerNewReview
										: answer
								}
								emailBCCListGlobal={emailBCCListGlobal}
								emailCCListGlobal={emailCCListGlobal}
								loadingSend={loadingAnswer}
							/>
						</Box>
						{/* Clear content */}
						<Tooltip title={i18n.t("COC.ClearAll")}>
							<Box mt={1}>
								<IconButton
									//	disabled={typeConv === "ticket" ? disableActions : disableClearConversational}
									size="small"
									onClick={handleClearAnswer}
								>
									<ClearIcon />
								</IconButton>
							</Box>
						</Tooltip>
						{/* Generate response for review */}
						{/* {selectedConversation.header.type === "review" && (
							<Tooltip title={i18n.t("COC.GenerateResponse")}>
								<Box mt={1}>
									<IconButton
										size="small"
										onClick={() =>
											setReplyAssistant({ open: true, processCode: "assistantReponseDemo" })
										}
										disabled={selectedConversation.actions?.find(
											a => a.code === "replyAssistant" && a.state === "processing"
										)}
									>
										<EmojiObjects />
									</IconButton>
								</Box>
							</Tooltip>
						)} */}

						{!lod_.isNil(editorActions) &&
							!lod_.isEmpty(editorActions) &&
							!lod_.isNil(
								selectedConversation?.messages.find(m => m.header.source === "contact")
							) && (
								<Tooltip title={""}>
									<Box mt={1}>
										<IconButton
											size="small"
											onClick={() => handleOpenReplyG(editorActions)}
											disabled={selectedConversation.actions?.find(
												a =>
													(a.code === "replyAssistant" || a.code === "rephraseReplyAssistant") &&
													a.state === "processing"
											)}
										>
											<EmojiObjects />
										</IconButton>
									</Box>
								</Tooltip>
							)}

						{shouldShowTranslateButton() && (
							<Tooltip title={i18n.t("COC.translate")}>
								<Box mt={1}>
									<IconButton size="small" onClick={translateDraft}>
										<TranslateIcon />
									</IconButton>
								</Box>
							</Tooltip>
						)}
						{shouldShowReplyAllButton(selectedConversation) && (
							<Tooltip title={i18n.t("COC.replyAll")}>
								<Box mt={1}>
									<IconButton size="small" onClick={replyAll}>
										<ReplyAllIcon />
									</IconButton>
								</Box>
							</Tooltip>
						)}

						<Popover
							id="translate"
							open={openTranslate}
							anchorEl={anchorTranslate}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "center",
								horizontal: "left"
							}}
							transformOrigin={{
								vertical: "center",
								horizontal: "right"
							}}
						>
							{selectedConversation.drafts?.default && (
								<IconButton onClick={() => handleSetDefaultDraft()}>
									<BackspaceIcon />
								</IconButton>
							)}
							{languages.map((lang, key) => (
								<>
									<Box
										display="flex"
										flexDirection="column"
										justifyContent="space-between"
										key={key}
									>
										<IconButton
											onClick={() =>
												handleTranslate(
													lang,
													topicDraft,
													selectedConversation.drafts?.version === "2.0"
														? selectedConversation?.header?.type === "ticket"
															? answerNew
															: answerNewReview
														: answer
												)
											}
										>
											<img
												key={key}
												src={GetFlag(lang.toUpperCase())}
												alt="Filter_flag"
												width="20"
												height="20"
											/>
										</IconButton>
									</Box>
								</>
							))}
						</Popover>

						{isChannelMail(selectedConversation) && (
							<Box mt={1}>
								<Tooltip title={i18n.t("COC.extraActions")}>
									<span>
										<IconButton
											aria-describedby="extraActions"
											disabled={typeConv !== "ticket"}
											size="small"
											onClick={event => setAnchorCCPopover(event.currentTarget)}
										>
											<StyledBadge
												badgeContent={lengthCC + lengthBCC}
												color="primary"
												showZero={false}
											>
												<MoreVertOutlinedIcon />
											</StyledBadge>
										</IconButton>
									</span>
								</Tooltip>

								<EmailCCList
									t={i18n.t}
									withUrgentSubject={true}
									anchorExtraActions={anchorExtraActions}
									setAnchorExtraActions={setAnchorExtraActions}
									anchorCCPopover={anchorCCPopover}
									setAnchorCCPopover={setAnchorCCPopover}
									emailCCList={emailCCListGlobal}
									setEmailCCList={setEmailCCListGlobal}
									setLengthCC={setLengthCC}
									setLengthBCC={setLengthBCC}
									emailBCCList={emailBCCListGlobal}
									setEmailBCCList={setEmailBCCListGlobal}
								></EmailCCList>
							</Box>
						)}

						{!lod_.isEmpty(
							JSON.parse(localStorage.getItem("presse-papier"))?.filter(
								filter => filter.assistantID === selectedConversation.header.assistantID
							)
						) &&
							selectedConversation.header.type !== "conversational" && (
								<>
									<Tooltip title={i18n.t("COC.infoCopyClipboard")}>
										<span>
											<IconButton
												aria-describedby="extraActions"
												size="small"
												onClick={showCopyAction}
											>
												<StyledBadge color="primary" showZero={false}>
													<FileCopyIcon />
												</StyledBadge>
											</IconButton>
										</span>
									</Tooltip>
									<Popover
										id="copyclipboard"
										open={openCopy}
										anchorEl={anchorCopy}
										onClose={handleCloseCopy}
										anchorOrigin={{
											vertical: "center",
											horizontal: "left"
										}}
										transformOrigin={{
											vertical: "center",
											horizontal: "right"
										}}
									>
										<Box style={{ display: "flex", flexDirection: "column" }}>
											<Button onClick={handleOpenCopyToClipboardDialog}>
												{i18n.t("COC.showCopyClipboard")}
											</Button>

											<Button onClick={handleAddCopyToClipboardDialog}>
												{i18n.t("COC.addCopyClipboard")}
											</Button>
										</Box>
									</Popover>
								</>
							)}

						<CopyToClipboardDialog
							open={openCopyToClipboardDialog}
							handleCloseCopyToClipboardDialog={handleCloseCopyToClipboardDialog}
						/>

						<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
							{(!signature && draftSignature) || (!replyForward && replyOrForw) ? (
								<Tooltip title={i18n.t("COC.seeSignature")}>
									<Chip label="..." onClick={handleClickSignature} />
								</Tooltip>
							) : (
								<Box></Box>
							)}
						</div>

						{postDraftLoading && (
							<Box mt="auto">
								<IconButton size="small">
									<CheckIcon color="primary" />
								</IconButton>
							</Box>
						)}
					</Box>
				</Box>
			</DragAndDrop>
			{/* Extra modales */}
			<ReplyAssistantModal
				open={replyAssistant.open}
				actions={replyGenerate.actions}
				onClose={() => setReplyAssistant({ open: false })}
			/>

			<GenerateAnswerReply
				open={replyGenerate.open}
				draftAnswer={answerNew}
				actions={replyGenerate.actions}
				onClose={() => setReplyGenerate({ open: false })}
			/>
		</div>
	);
});

const MemoizedEditMessageItem = memo(EditMessageItem);
export default MemoizedEditMessageItem;
